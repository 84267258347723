import React from "react";
import ImageDialog from "./ImageDialog";
import DangerButton from "../../layout/components/buttons/DangerButton";
import CloseButton from "../../layout/components/buttons/CloseButton";

const ImageViewer = ({ imagePaths, onDeleteClicked, deleteAllImages }) => {
  const [selectedImage, setSelectedImage] = React.useState(null);
  return (
    <div className="">
      {imagePaths.length > 1 && (
        <DangerButton label="Remove All" onClick={deleteAllImages} />
      )}
      <div className="flex flex-wrap flex-shrink-0 overflow-x-auto gap-5 pb-5">
        {imagePaths.map((img, index) => (
          <div
            key={index}
            className="relative shadow-lg rounded-md w-3/12 h-full  flex-shrink-0 bg-slate-100 shadow-slate-400"
          >
            <CloseButton onClick={(e) => onDeleteClicked(index)} />

            <img
              className="object-contain w-full rounded-md"
              src={img}
              alt={`Cropped ${index}`}
              onClick={() => setSelectedImage(img)}
            />
          </div>
        ))}
        <ImageDialog
          selectedImage={selectedImage}
          setSelectedImage={setSelectedImage}
        />
      </div>
    </div>
  );
};

export default ImageViewer;
