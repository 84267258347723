import React, { useEffect, useRef, useState } from "react";
import ReactCrop from "react-image-crop";
import { toast } from "react-toastify";
import { TransformComponent, TransformWrapper } from "react-zoom-pan-pinch";
import { useShallow } from "zustand/shallow";
import { uploadImageApi } from "../../api/ApiService";
import { useImageStore, useProcessStore } from "../../Store";
import { STEP_PAGES } from "../../utils/Constants";
import ImageViewer from "../ImageViewer/ImageViewer";
import DiscardChanges from "./DiscardChanges";
import WarningDialog from "./WarningDialog";
import SuccessButton from "../../layout/components/buttons/SuccessButton";

const ImageEditor = () => {
  const {
    goToStep,
    setIsLoading,
    setMenuItems,
    selectedModel,
    overwriteStore,
  } = useProcessStore(
    useShallow((state) => ({
      goToStep: state.goToStep,
      setIsLoading: state.setIsLoading,
      setMenuItems: state.setMenuItems,
      selectedModel: state.selectedModel,
      overwriteStore: state.overwriteStore,
    }))
  );
  const { imageFiles, imagePaths, croppedImages, setCroppedImages } =
    useImageStore(
      useShallow((state) => ({
        imageFiles: state.imageFiles,
        imagePaths: state.imagePaths,
        croppedImages: state.croppedImages,
        setCroppedImages: state.setCroppedImages,
      }))
    );
  const [currentImage, setCurrentImage] = useState(0);
  const [isOpen, setIsOpen] = useState(false);
  const [isZoom, setIsZoom] = useState(false);
  const [showDiscardModal, setShowDiscardModal] = useState(false);
  const [showCropedPopup, setShowCropedPopup] = useState(false);
  const [showCropedPopupQueue, setShowCropedPopupQueue] = useState(0);
  const [crop, setCrop] = useState({
    aspect: undefined,
  });
  const [state, setState] = useState({
    scale: 1,
    positionX: 0,
    positionY: 0,
  });
  const [croppedImagesURL, setCroppedImagesURL] = useState(
    croppedImages.map((blob) => {
      return URL.createObjectURL(blob);
    })
  );
  const imgRef = useRef(null);
  useEffect(() => {
    const imagesUrl = croppedImages.map((blob) => {
      return URL.createObjectURL(blob);
    });
    setCroppedImagesURL((prev) => [...imagesUrl]);
  }, [croppedImages]);

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.code === "ControlLeft") {
        event.preventDefault();
        setIsZoom(true);
        setCrop({ aspect: undefined });
      }
      if (event.code === "ShiftLeft") {
        event.preventDefault();
        setIsZoom(false);
      }
      if (event.code === "Space") {
        event.preventDefault();
        setIsZoom(true);
        setCrop({ aspect: undefined });
      } else if (event.code === "Tab") {
        event.preventDefault();
        setIsZoom((prev) => !prev);
      }
    };

    const handleKeyUp = (event) => {
      if (event.code === "ControlLeft") {
        setIsZoom(false);
      }
      if (event.code === "Space") {
        setIsZoom(false);
      }
      if (event.code === "ShiftLeft") {
        setIsZoom(true);
      }
    };

    window.addEventListener("keydown", handleKeyDown);
    window.addEventListener("keyup", handleKeyUp);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
      window.removeEventListener("keyup", handleKeyUp);
    };
  }, []);

  useEffect(() => {
    let popupTimeout;

    if (showCropedPopupQueue > 0 && !showCropedPopup) {
      popupTimeout = setTimeout(() => {
        setShowCropedPopup(true);

        const hidePopupTimeout = setTimeout(() => {
          setShowCropedPopup(false);
          setShowCropedPopupQueue((prev) => prev - 1);
          clearTimeout(hidePopupTimeout);
        }, 1600);
      }, 300);
    }

    return () => {
      if (popupTimeout) clearTimeout(popupTimeout);
    };
  }, [showCropedPopupQueue, showCropedPopup]);
  const handleCropImage = async () => {
    if (!crop.width || !crop.height) {
      return;
    }

    const image = imgRef.current;
    if (!image || !crop) {
      throw new Error("Crop canvas does not exist");
    }
    // Calculate scaling based on the image natural dimensions and the displayed size
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;

    // Adjust the crop area based on the zoom and pan values from state
    const adjustedX = (crop.x - state.positionX) / state.scale;
    const adjustedY = (crop.y - state.positionY) / state.scale;
    const adjustedWidth = crop.width / state.scale;
    const adjustedHeight = crop.height / state.scale;

    const offscreen = document.createElement("canvas");
    offscreen.width = adjustedWidth * scaleX;
    offscreen.height = adjustedHeight * scaleY;
    const ctx = offscreen.getContext("2d");

    if (!ctx) {
      console.error("No 2D context available on canvas");
      return;
    }

    ctx.drawImage(
      image,
      adjustedX * scaleX,
      adjustedY * scaleY,
      adjustedWidth * scaleX,
      adjustedHeight * scaleY,
      0,
      0,
      offscreen.width,
      offscreen.height
    );

    const blob = await new Promise((resolve) =>
      offscreen.toBlob(resolve, "image/png", 1)
    );
    setCroppedImages([...croppedImages, blob]);
    setCrop({ aspect: undefined });
    setShowCropedPopupQueue((prev) => prev + 1);
  };
  const uploadImages = () => {
    setIsOpen(false);
    const croppedImageFiles =
      croppedImages.length > 0 ? croppedImages : imageFiles;
    if (!selectedModel || croppedImageFiles.length === 0) {
      goToStep(STEP_PAGES.IMAGE_UPLOADER);
      return;
    }
    const formData = new FormData();
    croppedImageFiles.forEach((file, index) => {
      formData.append(`files`, file);
    });
    formData.append("modelName", selectedModel);

    setIsLoading(true);

    uploadImageApi(formData)
      .then((response) => {
        if (response.status !== 200) {
          toast.error(
            response?.data?.message ||
              response?.data?.error ||
              "Failed to extract menu data"
          );
        } else {
          setMenuItems(response.data?.data?.menu ?? []);
          goToStep(STEP_PAGES.MENU_REVIEW);
        }
      })
      .catch((err) => {
        toast.error(
          err?.response?.data?.message ||
            err?.response?.data?.error ||
            "Failed to extract menu data"
        );
      })
      .finally(() => {
        setIsLoading(false);
      });
  };
  const uploadMenuClicked = () => {
    if (!overwriteStore && croppedImages.length === 0) {
      setIsOpen(true);
      return;
    }
    uploadImages();
  };
  const backButtonClicked = () => {
    croppedImages.length > 0 ? setShowDiscardModal(true) : backButtonConfirm();
  };
  const backButtonConfirm = () => {
    setCroppedImages([]);
    setCroppedImagesURL([]);
    goToStep(STEP_PAGES.IMAGE_UPLOADER);
  };
  const deleteCroppedImageClicked = (index) => {
    const updatedImages = [...croppedImages];
    updatedImages.splice(index, 1);
    setCroppedImages(updatedImages);
  };

  return (
    <div className="w-full h-screen max-w-4xl overflow-y-auto mx-auto px-8 py-12 rounded shadow-lg relative bg-white">
      <div className="h-full w-full">
        <div
          div
          className="h-4/5 flex w-full items-center justify-center relative"
        >
          <button
            type="button"
            onClick={() => setIsZoom(!isZoom)}
            className="absolute left-3 top-3 z-30 inline-flex items-center justify-center w-auto p-2 overflow-hidden font-bold text-gray-500 transition-all duration-500 border border-blue-200 rounded-md cursor-pointer group ease bg-gradient-to-b from-white to-blue-50 hover:from-blue-50 hover:to-white active:to-white"
          >
            <span className="w-full h-0.5 absolute bottom-0 group-active:bg-transparent left-0 bg-blue-100"></span>
            <span className="h-full w-0.5 absolute bottom-0 group-active:bg-transparent right-0 bg-blue-100"></span>
            {/*
             */}{" "}
            {isZoom ? "Zoom" : "Crop"}
          </button>
          <button
            type="button"
            onClick={() => setCurrentImage((prev) => Math.max(prev - 1, 0))}
            className={`absolute left-3 bottom-3 z-30 inline-flex items-center justify-center w-auto p-2 overflow-hidden font-bold transition-all duration-500 border rounded-md cursor-pointer group ease bg-gradient-to-b from-white to-blue-50 hover:from-blue-50 hover:to-white active:to-white
                  ${
                    currentImage === 0
                      ? "text-gray-300 border-gray-300 cursor-not-allowed"
                      : "text-gray-500 border-blue-200 hover:text-gray-700"
                  }`}
            disabled={currentImage === 0}
          >
            Previous
          </button>

          <button
            type="button"
            onClick={() =>
              setCurrentImage((prev) =>
                Math.min(prev + 1, imagePaths.length - 1)
              )
            }
            className={`absolute right-3 bottom-3 z-30 inline-flex items-center justify-center w-auto p-2 overflow-hidden font-bold transition-all duration-500 border rounded-md cursor-pointer group ease bg-gradient-to-b from-white to-blue-50 hover:from-blue-50 hover:to-white active:to-white
                  ${
                    currentImage === imagePaths.length - 1
                      ? "text-gray-300 border-gray-300 cursor-not-allowed"
                      : "text-gray-500 border-blue-200 hover:text-gray-700"
                  }`}
            disabled={currentImage === imagePaths.length - 1}
          >
            Next
          </button>

          {imageFiles.length > 0 && (
            <ReactCrop
              mouseDownOnCrop={true}
              className="h-full text-center "
              minWidth={50}
              minHeight={50}
              crop={crop}
              onChange={(c) => setCrop(c)}
              disabled={isZoom}
            >
              <TransformWrapper
                disabled={!isZoom}
                onTransformed={(ref, state) => {
                  setState({ ...state });
                }}
              >
                {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
                  <>
                    <TransformComponent
                      wrapperClass="h-full w-full text-center"
                      contentClass="h-full w-full text-center"
                    >
                      <img
                        ref={imgRef}
                        className="h-full object-contain"
                        src={imagePaths[currentImage]}
                        alt="Menu"
                      />
                    </TransformComponent>
                  </>
                )}
              </TransformWrapper>
            </ReactCrop>
          )}
        </div>
        <div className="text-center">
          <div className="flex justify-around md:flex-row flex-col items-center">
            <button
              type="button"
              onClick={backButtonClicked}
              className="relative md:my-9 my-2 z-30 inline-flex items-center justify-center w-auto px-3 py-3 overflow-hidden font-bold text-gray-500 transition-all duration-500 border border-gray-200 rounded-md cursor-pointer group ease bg-gradient-to-b from-white to-gray-50 hover:from-gray-50 hover:to-white active:to-white"
            >
              <span className="w-full h-0.5 absolute bottom-0 group-active:bg-transparent left-0 bg-gray-100"></span>
              <span className="h-full w-0.5 absolute bottom-0 group-active:bg-transparent right-0 bg-gray-100"></span>
              {/*
               */}{" "}
              Back
            </button>
            <div className="relative">
              <button
                disabled={!crop.width || !crop.height}
                type="button"
                onClick={handleCropImage}
                className="relative md:my-9 my-2 z-30 inline-flex items-center justify-center w-auto px-3 py-3 overflow-hidden font-bold text-gray-500 transition-all duration-500 border border-blue-200 rounded-md cursor-pointer group ease bg-gradient-to-b from-white to-blue-50 hover:from-blue-50 hover:to-white active:to-white"
              >
                <span className="w-full h-0.5 absolute bottom-0 group-active:bg-transparent left-0 bg-blue-100"></span>
                <span className="h-full w-0.5 absolute bottom-0 group-active:bg-transparent right-0 bg-blue-100"></span>
                {/*
                 */}{" "}
                Crop Image
              </button>

              <div
                className={`${
                  showCropedPopup ? "opacity-1" : "opacity-0"
                } z-50 px-2 py-3 absolute left-1/2 -top-10 lg:-top-3 font-bold -translate-x-1/2 w-64 text-slate-100 divide-y rounded-xl bg-emerald-600/85 text-sm/4 transition duration-200 ease-in-out [--anchor-gap:var(--spacing-5)] data-[closed]:-translate-y-1 data-[closed]:opacity-0`}
              >
                <p>The image successfully cropped</p>
              </div>
            </div>

            <SuccessButton
              onClick={uploadMenuClicked}
              label={`Continue With 
                ${
                  croppedImages.length > 0 ? "Cropped Images" : "Orginal Images"
                }`}
            />
          </div>
        </div>
        <ImageViewer
          imagePaths={croppedImagesURL}
          onDeleteClicked={(index) => deleteCroppedImageClicked(index)}
          deleteAllImages={() => {
            setCroppedImages([]);
            setCroppedImagesURL([]);
          }}
        />
      </div>
      <WarningDialog
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        uploadImages={uploadImages}
      />
      <DiscardChanges
        backButtonConfirm={backButtonConfirm}
        isOpen={showDiscardModal}
        setIsOpen={setShowDiscardModal}
      />
    </div>
  );
};

export default ImageEditor;
