import axios from "axios";
const apiUrl = process.env.REACT_APP_API_URL;
export const uploadImageApi = async (formData) => {
  return await axios.post(
    apiUrl + "/franchise/store/menu-extractor",
    formData,
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }
  );
};
export const importDataIntoDB = async (menuItems, overwriteStore) => {
  return await axios.post(
    apiUrl + "/franchise/store/import-data",
    {
      menu: menuItems,
      overwriteStore,
    },
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
};

export const loginAPI = async (email, password) => {
  return await axios.post(apiUrl + "/franchise/logIn", {
    email,
    password,
    sub: "scraperInternJWT",
    exp: "1d",
  });
};
