import React from "react";
import PropTypes from "prop-types";

const ProductItem = ({ product }) => {
  // console.log(product);
  return (
    <div className="py-2 px-5">
      <div className="flex flex-row justify-between">
        <div>
          <p className="font-bold text-gray-700 text-xl">{product.name}</p>
          <p className="mt-1 text-gray-600 text-md">
            {product.description.length > 0 && product.description[0]}
          </p>
          {product.extras.length > 0 && (
            <p className="flex flex-wrap my-2 justify-start items-center">
              Addons:
              {product.extras.map((extra, index) => {
                const displyPrice = extra.price?.replace(/\s+/g, "");
                return (
                  <span
                    className="mx-1 bg-gray-200 p-1 px-2 rounded-full text-sm"
                    key={index}
                  >
                    {extra.name} {displyPrice ?? 0}
                  </span>
                );
              })}
            </p>
          )}
        </div>
        <div>
          <p className="font-bold text-gray-700 text-xl">{product.price}</p>
        </div>
      </div>
    </div>
  );
};
ProductItem.propTypes = {
  products: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      price: PropTypes.string.isRequired,
      description: PropTypes.array.isRequired,
      extras: PropTypes.arrayOf(
        PropTypes.shape({
          name: PropTypes.string.isRequired,
          price: PropTypes.string.isRequired,
        })
      ),
    }).isRequired
  ).isRequired,
};
export default ProductItem;
